import { gql } from '@apollo/client'

export const QueryAccounts = gql`
  query Accounts($role: String! = "", $sort: String, $query: String, $suspended: Boolean, $page: Int, $limit: Int) {
    accounts(role: $role, sort: $sort, page: $page, query: $query, suspended: $suspended, limit: $limit) {
      total
      results {
        id
        firstName
        middleName
        lastName
        email
        phone
        createdAt
        updatedAt
        suspendedAt
        isNotificationsEnabled

        roles

        permissions {
          documentTypes
          marketIds

          markets {
            id
            name
          }
        }
      }
    }
  }
`

export const QueryAccountWorkspace = gql`
  query AccountWorkspace($id: ID!) {
    workspace(id: $id) {
      id
      name
      setupCompletedAt
    }
  }
`
