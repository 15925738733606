import { UploadLink } from 'components/drivers/UploadLink/types'
import { store } from 'internal/redux'
import { GetServerSidePropsContext } from 'next'
import { Document } from 'types/Document'
import { get, patch, post } from './req'

export function extractHeaders(context: GetServerSidePropsContext) {
  return (
    context && {
      Authorization: context.req.headers['authorization'] || '',
      Cookie: context.req.headers['cookie'] || '',
    }
  )
}

const oneHourMs = 1000 * 60 * 60
export async function refreshAuth() {
  const lastRefresh = window.localStorage.getItem('lastRefresh')
  if (lastRefresh && Date.now() - parseInt(lastRefresh) < oneHourMs) {
    return
  }

  return get('/v1/auth/refresh').then(() => {
    window.localStorage.setItem('lastRefresh', Date.now().toString())
  })
}

export async function switchProvider(providerId: string) {
  return post('/v1/auth/provider', { providerId })
}

export async function getAccountFromContext(context: GetServerSidePropsContext) {
  const profile = await fetch(process.env.NEXT_PUBLIC_API_BASE_URL + '/v1/auth/profile', {
    headers: extractHeaders(context),
  })
    .then(r => (r.ok ? r.json() : null))
    .catch(e => {
      console.error(e)
      return null
    })

  return profile
}

type UpdateProfilePayload = {
  firstName?: string
  lastName?: string
  phone?: string
  isNotificationsEnabled?: boolean
  accountId?: string
  skipConfirm?: boolean
}

export async function updateProfile(payload: UpdateProfilePayload) {
  return patch(`/v1/accounts/profile`, payload)
}

export async function createTrackingProvider(data) {
  return fetch(process.env.NEXT_PUBLIC_API_BASE_URL + '/tracking-providers', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ providers: [data] }),
  })
}

export async function removeTrackingProvider(id) {
  return fetch(process.env.NEXT_PUBLIC_API_BASE_URL + '/tracking-providers', {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ medicaidProviderIds: [id] }),
  })
}

export type GetDocumentUploadUrlOptions = {
  mime: string
  name: string
  checksum: string
  documentType?: string
  inputDocumentType?: string
  link: LinkOptions
  uploadLinkToken?: string
}

export async function getDocumentUploadUrl(data: GetDocumentUploadUrlOptions) {
  const { ...body } = data
  return fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/documents`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  })
}

export async function completeDocumentUpload(params: any) {
  let { context, ...body } = params
  if (!context) {
    context = store.getState().auth.context
  }
  return fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/documents/complete`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then(async r => {
    if (r.ok) {
      return r.json() as Promise<{ document: Document }>
    } else {
      throw new Error(await r.text())
    }
  })
}

type RejectUploadInput = {
  documentId: string
  documentName: string
  uploadLinkToken: string
  reason: string
}

export async function rejectUpload(input: RejectUploadInput) {
  return fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/documents/reject-upload`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(input),
  }).then(async r => {
    if (r.ok) {
      return r.json() as Promise<{ document: Document }>
    } else {
      throw new Error(await r.text())
    }
  })
}

type ProviderRequestDocumentsInput = {
  driverId?: string
  vehicleId?: string
  message: string
}

export async function providerRequestDocuments(input: ProviderRequestDocumentsInput) {
  return fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/upload-link`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(input),
  }).then(async r => {
    if (r.ok) {
      return r.json() as Promise<{ uploadLink: UploadLink }>
    } else {
      throw new Error(await r.text())
    }
  })
}

export async function setDocumentPrimary(data: {
  documentId: string
  driverId?: string
  vehicleId?: string
  providerId?: string
}) {
  return fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/documents/primary`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export type LinkDocumentPayload = {
  documentId: string
  driverId?: string
  providerId?: string
  vehicleId?: string
}

export async function linkDocument(data: LinkDocumentPayload) {
  if (!data.driverId && !data.providerId && !data.vehicleId) {
    throw new Error('one of driverId, vehicleId, or providerId must be provided to link a document')
  }

  return fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/documents/link`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export async function updateDriverDocument(data: any) {
  return fetch(process.env.NEXT_PUBLIC_API_BASE_URL + '/drivers/documents', {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export async function uploadFile(url, file) {
  return fetch(url, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': file.type,
    },
  })
}

type UploadOptions = {
  documentType?: string
  completeOptions?: CompleteUploadOptions
  link?: LinkOptions
}

type CompleteUploadOptions = {
  analyze?: boolean
}

type LinkOptions = {
  providerId?: string
  driverId?: string
  vehicleId?: string
}

export async function uploadAndCompleteFile(file, options?: UploadOptions) {
  const context = store.getState().auth.context
  if (!file.type || !file.name || !context) {
    return null
  }

  const { uploadUrl, uploadToken, error } = await getDocumentUploadUrl({
    checksum: Date.now().toString(),
    mime: file.type,
    name: file.name,
    documentType: options?.documentType,
    link: options?.link,
  }).then(r => r.json())

  if (error) {
    throw new Error(error)
  }

  await uploadFile(uploadUrl, file)
  const { document } = await completeDocumentUpload({
    context,
    uploadToken,
  })

  return document
}

type UpdateDriverPayload = {
  id: string
  driverNumber?: string
  firstName?: string
  middleName?: string
  lastName?: string
  suffix?: string
  phone?: string
  email?: string
  dob?: string
  streetAddress?: string
  streetAddress2?: string
  city?: string
  state?: string
  zip?: string
  ssn?: string
  isActive?: boolean
  documentGroupId?: string
}

export async function updateDriver(data: UpdateDriverPayload) {
  return fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/drivers`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export async function addDriver(data: any) {
  return fetch(process.env.NEXT_PUBLIC_API_BASE_URL + '/v1/drivers', {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
}

export async function addVehicle(data: any) {
  return fetch(process.env.NEXT_PUBLIC_API_BASE_URL + '/v1/vehicles', {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
}

export async function updateVehicle(data: any) {
  return fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/vehicles`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export async function inviteDriver(data: any) {
  return fetch(process.env.NEXT_PUBLIC_API_BASE_URL + '/provider.inviteDriver', {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
}

export type InviteUserPayload = {
  email: string
  role: string
  firstName?: string
  lastName?: string
  providerId?: string
  workspaceId?: string
  marketId?: string
  requirementGroupId?: string
  details?: Record<string, any>
}
export async function inviteUser(data: InviteUserPayload) {
  return fetch(process.env.NEXT_PUBLIC_API_BASE_URL + '/v1/accounts/invite', {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
}

export type RemoveAccountPayload = {
  accountId: string
  providerId?: string
}
export async function removeAccount(data: RemoveAccountPayload) {
  return post('/v1/accounts/remove', data)
}

type ResendUserInvitePayload = {
  id: string
}

export async function resendUserInvite(data: ResendUserInvitePayload) {
  return fetch(process.env.NEXT_PUBLIC_API_BASE_URL + '/v1/accounts/resend-invite', {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
}

type AcceptInvitePayload = {
  firstName: string
  middleName?: string
  lastName: string
  phone: string
  token: string
}
export async function acceptInvite(data: AcceptInvitePayload) {
  return fetch(process.env.NEXT_PUBLIC_API_BASE_URL + '/v1/accounts/invite/accept', {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
}

type CancelInvitePayload = {
  id: string
}

export async function cancelInvite(data: CancelInvitePayload) {
  return fetch(process.env.NEXT_PUBLIC_API_BASE_URL + '/v1/accounts/cancel-invite', {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
}

export async function generateProviderReport(providerId: string) {
  const { context } = store.getState().auth
  return fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/${context}.generateProviderReport`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ providerId }),
  })
}
export async function generateDriverReport(driverId: string) {
  const { context } = store.getState().auth
  return fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/${context}.generateDriverReport`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ driverId }),
  })
}

export async function predict(img: string) {
  if (process.env.NEXT_PUBLIC_IS_LOCAL === '1') {
    return { Unknown: 1 }
  }

  const formData = new FormData()
  formData.append('img', img.split(';base64,').pop())

  try {
    const prediction = await fetch('https://document-scanner.onrender.com/predict', {
      method: 'POST',
      body: formData,
    }).then(r => r.json())
    return Object.fromEntries(
      Object.entries(prediction).sort((a, b) => {
        return a[1] > b[1] ? -1 : a[1] < b[1] ? 1 : 0
      }),
    )
  } catch {
    return { Unknown: 1 }
  }
}

export * as admin from './admin'
export * from './auth'
export * from './authorize'
export * from './exemption'
export * as providers from './providers'
export * as setup from './setup'
export * as vehicles from './vehicles'
